/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import http from '../../../../../http-common'
import {useHistory} from 'react-router-dom'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'

interface ResellerRejectModelProps {
  resellerId: string
}

const ResellerRejectModel: React.FC<ResellerRejectModelProps> = (resellerId) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const history = useHistory()

  const initialValues = {
    reject_reason: '',
  }
  const validationSchema = Yup.object().shape({
    reject_reason: Yup.string().required('Please Select Reason for Reject'),
  })

  const onSubmit = (data: any) => {
    const reseller_id = resellerId?.resellerId
    setLoading(true)
    setTimeout(() => {
      const setData = {
        reject_reason: data.reject_reason,
        status: 'Rejected',
        updated_by: getUser?.id,
      }
      http
        .post(`/resellers/reseller_status_update/${reseller_id}`, setData)
        .then((response) => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            history.push('/reseller_management/pending_list')
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          setError(true)
          console.log(error)
        })
    }, 1000)
  }
  return (
    <div className='modal fade' id='kt_modal_reseller_reject_model' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                <div className='text-center mb-13'>
                  <h1 className='mb-3'>Confirmation Message</h1>

                  <div className='text-muted fw-bold fs-5'>
                    Are you sure want to
                    <a href='#' className='link-danger fw-bolder me-1'>
                      {' '}
                      Reject
                    </a>
                    the reseller?
                  </div>
                </div>

                <div className='text-center fw-bolder w-100 mb-8'>
                  {/*begin::Input group*/}
                  <div className='d-flex flex-column mb-7 fv-row'>
                    {/*begin::Label*/}
                    <label className='fs-6 fw-semibold mb-2'>
                      <span className='required'>Reason for Reject </span>
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input group*/}
                    <div className='d-flex flex-column mb-7 fv-row'>
                      {/*begin::Label*/}
                      <label className='fs-6 fw-semibold mb-2'>
                        <span className='required'>Reseller Designation Type </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <Field
                        as='select'
                        name='reject_reason'
                        id='reject_reason'
                        aria-label='Reseller Designation Type'
                        data-control='select2'
                        data-placeholder='Reseller Designation Type...'
                        data-dropdown-parent='#kt_modal_add_customer'
                        className='form-select form-select-solid fw-bold'
                      >
                        <option>Select Reason for Reject ...</option>
                        <option value='Reason 1'>Select Reason 1</option>
                        <option value='Reason 2'>Select Reason 2</option>
                        <option value='Reason 3'>Select Reason 3</option>
                        <option value='Reason 4'>Select Reason 4</option>
                      </Field>
                      <ErrorMessage name='reject_reason' className='text-danger' component='span' />
                      {/*end::Input*/}
                    </div>
                    {/*begin::Input group*/}
                    {/*begin::Input*/}
                    {/* <Field
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Reason for Reject'
                      name='reject_reason'
                      id='reject_reason'
                    />
                    <ErrorMessage name='reject_reason' className='text-danger' component='span' /> */}
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {error && <p className='text-center text-danger'>Something Went Wrong</p>}
                {success && <p className='text-center text-success'>Reseller is Rejected</p>}
                {/*begin::Modal footer*/}
                <div className='modal-footer flex-center'>
                  {/*begin::Button*/}
                  <button
                    type='reset'
                    id='kt_modal_add_customer_cancel'
                    data-bs-dismiss='modal'
                    data-kt-users-modal-action='close'
                    className='btn btn-light me-3'
                  >
                    Cancel
                  </button>
                  {/*end::Button*/}
                  {/*begin::Button*/}
                  <button
                    type='submit'
                    id='kt_modal_add_customer_submit'
                    className='btn btn-danger'
                  >
                    {!loading && <span className='indicator-label'>Reject</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {ResellerRejectModel}
