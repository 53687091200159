/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues, Field, ErrorMessage, validateYupSchema} from 'formik'
import * as Yup from 'yup'
import http from '../../../../http-common'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

//File Uploads
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, {IDropzoneProps, ILayoutProps} from 'react-dropzone-uploader'

const LayoutImg = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: {maxFiles},
}: ILayoutProps) => {
  return (
    <div>
      {previews}
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {files.length > 0 && submitButton}
    </div>
  )
}

const AddStorePartnerModel: FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getStoreLocations, setStoreLocations] = useState([])
  const [getStoresByDistrict, setStoresByDistrict] = useState([])
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  // image uploade
  const [getImgFile, setImgFile] = useState([] as any)

  const initialValues = {
    store_id: '',
    store_admin_name: '',
    employee_type: '',
    store_admin_email: '',
    store_admin_password: '',
    store_admin_phone: '',
    alternate_email: '',
    pancard: '',
    adhar_card: '',
    address: '',
    reference_1: '',
    reference1_contact_no: '',
    reference_2: '',
    reference2_contact_no: '',
    status: '',
    created_by: getUser?.id,
  }

  const validateSchema = Yup.object().shape({
    district: Yup.string().required('Select District Field'),
    store_id: Yup.string().required('Select Store Field'),
    store_admin_name: Yup.string().required('Store Admin Name is required'),
    employee_type: Yup.string().required('Store Admin Type required'),
    store_admin_email: Yup.string()
      .required('Store Admin Email required')
      .email('Invalid email address. Please enter a valid email')
      .test('Unique Store Email', 'Store Email already exits', function (store_admin_email: any) {
        return new Promise((resolve, reject) => {
          http
            .get(`storeadmins/check_store_partner_email/${store_admin_email}/0`)
            .then((response) => {
              if (response.data.notExist === true) {
                resolve(true)
              } else {
                resolve(false)
              }
            })
        })
      }),
    store_admin_password: Yup.string().required('Store Admin login password required'),
    store_admin_phone: Yup.string()
      .required('Store Admin Contact is required')
      .matches(/^[0-9]{10}$/, 'Invalid phone number. Please enter a 10-digit number')
      .test(
        'Unique Store Email',
        'Store Admin Contact is already exits',
        function (store_admin_phone: any) {
          return new Promise((resolve, reject) => {
            http
              .get(`storeadmins/check_store_admin_contact/${store_admin_phone}/0`)
              .then((response) => {
                if (response.data.notExist === true) {
                  resolve(true)
                } else {
                  resolve(false)
                }
              })
          })
        }
      ),
    alternate_email: Yup.string()
      .required('Alternate Email required')
      .email('Invalid email address. Please enter a valid email')
      .test(
        'Unique Store Email',
        'Alternative Email and Store Admin Email should not be the same',
        function (alternate_email, {parent}) {
          const storeAdminEmail = parent.store_admin_email
          // Check if alternate_email is not the same as store_admin_email
          if (alternate_email !== storeAdminEmail) {
            return true
          } else {
            return false
          }
        }
      ),
    pancard: Yup.string()
      .required('PAN Card required')
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        'Invalid PAN Card Number. Please enter a valid PAN'
      ),
    adhar_card: Yup.string()
      .required('AdharCard required')
      .matches(/^\d{12}$/, 'Invalid Aadhaar Card Number. Please enter a 12-digit number'),
    address: Yup.string().required('Address required'),
  })

  useEffect(() => {
    http
      .get('/stores/store_locations')
      .then((Response) => setStoreLocations(Response.data))
      .catch((error) => console.log(error))
  }, [])

  const HandleDistrictOption = async (e: any, setFieldValue: any) => {
    const districtOption = e?.target?.value
    try {
      const getData = await http.get(`/stores/stores_by_district/${districtOption}`)
      if (getData?.data.length > 0) {
        setStoresByDistrict(getData?.data)
        getData?.data?.map((storeDet: any) => {
          return (
            // setFieldValue('store_id', storeDet?.id || ''),
            setFieldValue('district', districtOption || '')
          )
        })
      } else {
        setStoresByDistrict([])
        setFieldValue('store_id', '')
        setFieldValue('district', '')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeImages = (file: any, meta: any, allFiles: any) => {
    setImgFile(allFiles)
  }
  const onSubmit = (data: any) => {
    setLoading(true)
    let formData = new FormData()
    getImgFile.forEach((f: any) => {
      formData.append('documents', f.file, f.name)
    })

    formData.append('store_id', data.store_id)
    formData.append('store_admin_name', data.store_admin_name)
    formData.append('employee_type', data.employee_type)
    formData.append('store_admin_email', data.store_admin_email)
    formData.append('store_admin_password', data.store_admin_password)
    formData.append('store_admin_phone', data.store_admin_phone)
    formData.append('alternate_email', data.alternate_email)
    formData.append('pancard', data.pancard)
    formData.append('adhar_card', data.adhar_card)
    formData.append('address', data.address)
    formData.append('reference_1', data.reference_1)
    formData.append('reference_2', data.reference_2)
    formData.append('reference1_contact_no', data.reference1_contact_no)
    formData.append('reference2_contact_no', data.reference2_contact_no)
    formData.append('status', data.status)
    formData.append('created_by', getUser?.id)
    http
      .post('/storeadmins/add_store_admin', formData)
      .then((response) => {
        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .catch((error) => {
        setLoading(false)
        setError(true)
        console.log(error)
      })
  }
  return (
    <div className='modal fade' id='kt_modal_add_store_partner' tabIndex={-1} aria-hidden='true'>
      {/*begin::Modal dialog*/}
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        {/*begin::Modal content*/}
        <div className='modal-content'>
          {/*begin::Form*/}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validateSchema}
          >
            {({values, setFieldValue}) => (
              <Form className='form' action='#' id='kt_modal_add_customer_form'>
                {/*begin::Modal header*/}
                <div className='modal-header' id='kt_modal_add_customer_header'>
                  {/*begin::Modal title*/}
                  <h2 className='fw-bold'>Add Store in Administrator</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div
                    id='kt_modal_add_customer_close'
                    data-bs-dismiss='modal'
                    data-kt-users-modal-action='close'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x={6}
                          y='17.3137'
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(-45 6 17.3137)'
                          fill='currentColor'
                        />
                        <rect
                          x='7.41422'
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(45 7.41422 6)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Modal body*/}
                <div className='modal-body py-10 px-lg-17'>
                  {/*begin::Scroll*/}
                  <div
                    className='scroll-y me-n7 pe-7'
                    id='kt_modal_add_customer_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_customer_header'
                    data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    {/*begin::Input group*/}
                    <div className='d-flex flex-column mb-7 fv-row'>
                      {/*begin::Label*/}
                      <label className='fs-6 fw-semibold mb-2'>
                        <span className='required'>Select District</span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <Field
                        as='select'
                        name='district'
                        id='district'
                        aria-label='Select a State'
                        data-control='select2'
                        data-placeholder='Select a State...'
                        data-dropdown-parent='#kt_modal_add_customer'
                        className='form-select form-select-solid fw-bold'
                        onChange={(e: any) => HandleDistrictOption(e, setFieldValue)}
                      >
                        <option>Select State...</option>
                        {getStoreLocations?.map((locationData: any, index) => {
                          return (
                            <option key={index} value={locationData?.district}>
                              {locationData?.district +
                                ', ' +
                                locationData?.state +
                                ', ' +
                                locationData?.country}
                            </option>
                          )
                        })}
                      </Field>
                      {/*end::Input*/}
                      <div className='fs-6 text-success'> District,State,Country</div>
                      <ErrorMessage name='district' className='text-danger' component='span' />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className='d-flex flex-column mb-7 fv-row'>
                      {/*begin::Label*/}
                      <label className='fs-6 fw-semibold mb-2'>
                        <span className='required'>Select Stores</span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <Field
                        as='select'
                        name='store_id'
                        id='store_id'
                        aria-label='Select a State'
                        data-control='select2'
                        data-placeholder='Select a State...'
                        data-dropdown-parent='#kt_modal_add_customer'
                        className='form-select form-select-solid fw-bold'
                      >
                        <option>Select Store...</option>
                        {getStoresByDistrict?.map((stores_data: any, index) => {
                          return (
                            <option key={index} value={stores_data?.id}>
                              {stores_data?.store_name
                                ? stores_data?.store_name
                                : 'No Stores Found'}
                            </option>
                          )
                        })}
                      </Field>

                      {/*end::Input*/}
                      <ErrorMessage name='store_id' className='text-danger' component='span' />
                    </div>
                    {/*end::Input group*/}
                    {/*end::Input group*/}
                    <div className='row g-9 mb-7'>
                      {/*begin::Col*/}
                      <div className='col-md-12 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'>
                          {' '}
                          Store Administrator Name{' '}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Enter The Store Name'
                          name='store_admin_name'
                          id='store_admin_name'
                        />
                        <ErrorMessage
                          name='store_admin_name'
                          component='span'
                          className='text-danger'
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className='d-flex flex-column mb-7 fv-row'>
                      {/*begin::Label*/}
                      <label className='fs-6 fw-semibold mb-2'>
                        <span className='required'>Select Store Administrator Type</span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <Field
                        as='select'
                        name='employee_type'
                        id='employee_type'
                        aria-label='Select a State'
                        data-control='select2'
                        data-placeholder='Select a State...'
                        data-dropdown-parent='#kt_modal_add_customer'
                        className='form-select form-select-solid fw-bold'
                      >
                        <option value=''>Select Administrator Type...</option>
                        <option value='Store_Admin'>Administrator</option>
                        <option value='Store_employee'> Employee</option>
                      </Field>
                      <ErrorMessage name='employee_type' className='text-danger' component='span' />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    <div className='row g-9 mb-7'>
                      {/*begin::Col*/}
                      <div className='col-md-6 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'>
                          {' '}
                          Administrator Login mail Id{' '}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='email'
                          className='form-control form-control-solid'
                          placeholder='login@mailid.com'
                          name='store_admin_email'
                          id='store_admin_email'
                        />
                        <ErrorMessage
                          name='store_admin_email'
                          className='text-danger'
                          component='span'
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className='col-md-6 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'> Login Password</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='*********'
                          name='store_admin_password'
                          id='store_admin_password'
                        />
                        <ErrorMessage
                          name='store_admin_password'
                          className='text-danger'
                          component='span'
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    <div
                      className='fw-bold fs-3 rotate collapsible mb-7'
                      data-bs-toggle='collapse'
                      // href='#kt_modal_add_customer_billing_info'
                      role='button'
                      aria-expanded='false'
                      aria-controls='kt_customer_view_details'
                    >
                      Store Contact Information
                      <span className='ms-2 rotate-180'>
                        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                        <span className='svg-icon svg-icon-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        {/*end::Svg Icon*/}
                      </span>
                    </div>
                    {/*end::Billing toggle*/}
                    {/*begin::Billing form*/}
                    <div id='kt_modal_add_customer_billing_info' className='collapse show'>
                      {/*begin::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>
                            {' '}
                            Store Admin Contact No{' '}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='xxxxxxxxx'
                            name='store_admin_phone'
                            id='store_admin_phone'
                          />
                          <ErrorMessage
                            name='store_admin_phone'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>
                            Store Admin Alternate Mail Id
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Admin@Mail.com'
                            name='alternate_email'
                            id='alternate_email'
                          />
                          <ErrorMessage
                            className='text-danger'
                            name='alternate_email'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>
                            {' '}
                            Store Admin Pancad No{' '}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='xxxxxxxxxx'
                            name='pancard'
                            id='pancard'
                          />
                          <ErrorMessage name='pancard' className='text-danger' component='span' />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>
                            Store Admin Adhar card No{' '}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='xxxxxxxx'
                            name='adhar_card'
                            id='adhar_card'
                          />
                          <ErrorMessage
                            name='adhar_card'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='d-flex flex-column mb-7 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'>
                          Store Admin Address Line 1
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Enter Store Admin Address'
                          name='address'
                          id='address'
                        />
                        <ErrorMessage name='address' className='text-danger' component='span' />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold mb-2'> Reference 01</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Reference Name 01'
                            name='reference_1'
                            id='reference_1'
                          />
                          <ErrorMessage
                            name='reference_1'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className=' fs-6 fw-semibold mb-2'> Referance Contact No </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Reference Contact 01'
                            name='reference1_contact_no'
                            id='reference1_contact_no'
                          />
                          <ErrorMessage
                            name='reference1_contact_no'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className=' fs-6 fw-semibold mb-2'> Referance 02</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Reference Name 02'
                            name='reference_2'
                            id='reference_2'
                          />
                          <ErrorMessage
                            name='reference_2'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className=' fs-6 fw-semibold mb-2'> Referance Contact No </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Reference Contact 02'
                            name='reference2_contact_no'
                            id='reference2_contact_no'
                          />
                          <ErrorMessage
                            name='reference2_contact_no'
                            className='text-danger'
                            component='span'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='d-flex flex-column mb-7 fv-row'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-semibold mb-2'>
                          Documenrs Up Loded | Pancard | Adhar Card | Driving Lience
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <div className='me-0'>
                          {/*begin::Label*/}
                          <div className='form-group'>
                            <Dropzone
                              // multiple={true}
                              onChangeStatus={handleChangeImages}
                              LayoutComponent={LayoutImg}
                              inputContent='Upload images'
                              accept='image/*'
                              maxFiles={20}
                            />
                          </div>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <div className='fs-7 fw-semibold text-muted'>
                            {' '}
                            please Uploded Documnets Photos
                          </div>
                          {/*end::Input*/}
                        </div>
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                    </div>
                    {/*end::Billing form*/}

                    {/*end::Billing toggle*/}
                    {/*begin::Billing form*/}
                    <div id='kt_modal_add_customer_billing_info' className='collapse show'>
                      {/*begin::Input group*/}
                      <div className='d-flex flex-stack'>
                        {/*begin::Label*/}
                        <div className='me-5'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold'>Store Admin Partner Status</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <div className='fs-7 fw-semibold text-muted'>
                            {' '}
                            please check Stores Partner Condications
                          </div>
                          {/*end::Input*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Switch*/}
                        <label className='form-check form-switch form-check-custom form-check-solid'>
                          {/*begin::Input*/}
                          <Field
                            className='form-check-input'
                            name='status'
                            id='status'
                            type='checkbox'
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <span
                            className='form-check-label fw-semibold text-muted'
                            //   htmlFor='kt_modal_add_customer_billing'
                          >
                            Active
                          </span>
                          {/*end::Label*/}
                        </label>
                        {/*end::Switch*/}
                      </div>
                    </div>
                    {/*end::Billing form*/}
                  </div>
                  {/*end::Scroll*/}
                  {error && <p className='text-center text-danger'>Something Went Wrong</p>}
                  {success && <p className='text-center text-success'>Store Added Successfully</p>}
                </div>
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className='modal-footer flex-center'>
                  {/*begin::Button*/}
                  <button
                    type='reset'
                    id='kt_modal_add_customer_cancel'
                    className='btn btn-light me-3'
                    data-bs-dismiss='modal'
                  >
                    Cancel
                  </button>
                  {/*end::Button*/}
                  {/*begin::Button*/}
                  <button
                    type='submit'
                    id='kt_modal_add_customer_submit'
                    className='btn btn-primary'
                  >
                    {!loading && <span className='indicator-label'>Add Admin Store</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </Form>
            )}
          </Formik>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  )
}

export {AddStorePartnerModel}
