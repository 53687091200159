import React, {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {Formik, Form, FormikValues, Field, ErrorMessage, validateYupSchema} from 'formik'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import http from '../../../../http-common'
import Dropzone, {ILayoutProps} from 'react-dropzone-uploader'

const LayoutImg = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: {maxFiles},
}: ILayoutProps) => {
  return (
    <div>
      {previews}
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {files.length > 0 && submitButton}
    </div>
  )
}

export default function StoreAdminAddResellerFormModel() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getDesignationTypes, setDesignationTypes] = useState([])
  const [getCreditZone, setCreditZone] = useState([])
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  // Reseller Documents Upload
  const [getImgFile, setImgFile] = useState([])

  useEffect(() => {
    http
      .get('/reselller_designation_types')
      .then((Response) => setDesignationTypes(Response.data))
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    http
      .get('/reseller_types')
      .then((Response) => setCreditZone(Response.data))
      .catch((error) => console.log(error))
  }, [])

  const initialValues = {
    reseller_designation_id: '',
    credit_zone_type_id: '',
    reseller_name: '',
    reseller_contact_no: '',
    reseller_email_id: '',
    reseller_password: '',
    date_of_birth: '',
    reseller_pancard: '',
    reseller_adhar_card: '',
    reseller_address: '',
    reseller_reference_1: '',
    reseller_reference_contact_1: '',
    reseller_reference_2: '',
    reseller_reference_contact_2: '',
    // status: 'Pending',
  }

  const validateSchema = Yup.object().shape({
    reseller_designation_id: Yup.string().required('Designation Type is required'),
    credit_zone_type_id: Yup.string().required('Credit Zone required'),
    reseller_name: Yup.string().required('Reseller Name is required'),
    reseller_contact_no: Yup.string()
      .required('Reseller Contact is required')
      .matches(/^[0-9]{10}$/, 'Invalid phone number. Please enter a 10-digit number')
      .test(
        'Unique Store Email',
        'Reseller Contact is already exits',
        function (reseller_contact_no: any) {
          return new Promise((resolve, reject) => {
            http.get(`resellers/check_reseller_contact/${reseller_contact_no}`).then((response) => {
              if (response.data.notExist === true) {
                resolve(true)
              } else {
                resolve(false)
              }
            })
          })
        }
      ),
    reseller_email_id: Yup.string()
      .required('Reseller Email is required')
      .email('Invalid email address. Please enter a valid email')
      .test('Unique Store Email', 'Email already exits', function (reseller_email_id: any) {
        return new Promise((resolve, reject) => {
          http.get(`resellers/check_reseller_email/${reseller_email_id}`).then((response) => {
            if (response.data.notExist === true) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        })
      }),
    reseller_password: Yup.string().required('Reseller New Password is required'),
    date_of_birth: Yup.string().required('Date Of Birth is required'),
    reseller_pancard: Yup.string()
      .required('PAN Card Number is required')
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        'Invalid PAN Card Number. Please enter a valid PAN'
      ),
    reseller_adhar_card: Yup.string()
      .required('AdharCard required')
      .matches(/^\d{12}$/, 'Invalid Aadhaar Card Number. Please enter a 12-digit number'),
    reseller_address: Yup.string().required('Address required'),
    reseller_reference_1: Yup.string().required('Reference is required'),
    reseller_reference_contact_1: Yup.string().required('Reference contact is required'),
    reseller_reference_2: Yup.string().required('Reference is required'),
    reseller_reference_contact_2: Yup.string().required('Reference contact is required'),
    // status: Yup.string().required('Status required'),
  })

  const handleChangeImages = (file: any, meta: any, allFiles: any) => {
    setImgFile(allFiles)
  }

  function convertToUpperCase(event: any) {
    const inputField = event.target
    inputField.value = inputField.value.toUpperCase()
  }

  const onsubmit = (data: any) => {
    let formData = new FormData()
    getImgFile.forEach((f: any) => {
      formData.append('documents', f.file, f.name)
    })
    formData.append('store_id', getUser?.store_id)
    formData.append('store_partner_id', getUser?.id)
    formData.append('reseller_designation_id', data?.reseller_designation_id)
    formData.append('credit_zone_type_id', data?.credit_zone_type_id)
    formData.append('reseller_name', data?.reseller_name)
    formData.append('reseller_contact_no', data?.reseller_contact_no)
    formData.append('reseller_email_id', data?.reseller_email_id)
    formData.append('reseller_password', data?.reseller_password)
    formData.append('date_of_birth', data?.date_of_birth)
    formData.append('reseller_pancard', data?.reseller_pancard)
    formData.append('reseller_adhar_card', data?.reseller_adhar_card)
    formData.append('reseller_address', data?.reseller_address)
    formData.append('reseller_reference_1', data?.reseller_reference_1)
    formData.append('reseller_reference_contact_1', data?.reseller_reference_contact_1)
    formData.append('reseller_reference_2', data?.reseller_reference_2)
    formData.append('reseller_reference_contact_2', data?.reseller_reference_contact_2)
    formData.append('status', 'Pending')
    formData.append('created_by', getUser?.id)
    setLoading(true)
    setTimeout(() => {
      http
        .post('/resellers/add_reseller_by_store_admin', formData)
        .then((response) => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          setError(true)
          console.log(error)
        })
    }, 1000)
  }
  return (
    <Formik initialValues={initialValues} validationSchema={validateSchema} onSubmit={onsubmit}>
      <Form className='form'>
        {/*begin::Modal header*/}
        <div className='modal-header' id='kt_modal_add_customer_header'>
          {/*begin::Modal title*/}
          <h2 className='fw-bold'>Add Reseller</h2>
          {/*end::Modal title*/}
          {/*begin::Close*/}
          <div
            id='kt_modal_add_customer_close'
            data-bs-dismiss='modal'
            data-kt-users-modal-action='close'
            className='btn btn-icon btn-sm btn-active-icon-primary'
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
            <span className='svg-icon svg-icon-1'>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x={6}
                  y='17.3137'
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(-45 6 17.3137)'
                  fill='currentColor'
                />
                <rect
                  x='7.41422'
                  y={6}
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(45 7.41422 6)'
                  fill='currentColor'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className='modal-body py-10 px-lg-17'>
          {/*begin::Scroll*/}
          <div
            className='scroll-y me-n7 pe-7'
            id='kt_modal_add_customer_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_customer_header'
            data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
            data-kt-scroll-offset='300px'
          >
            {/*begin::Input group*/}
            <div className='d-flex flex-column mb-7 fv-row'>
              {/*begin::Label*/}
              <label className='required fs-6 fw-semibold mb-2'>
                Partner Admin / Employee Name
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <label className='form-control form-control-solid'>
                <span className='me-3'>{getUser?.name}</span>
                <span>({getUser?.user_type})</span>
              </label>
              <div className='fs-6 text-success'> Register Store,city,District,State,Country</div>
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className='d-flex flex-column mb-7 fv-row'>
              {/*begin::Label*/}
              <label className='fs-6 fw-semibold mb-2'>
                <span className='required'>Reseller Designation Type </span>
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <Field
                as='select'
                name='reseller_designation_id'
                id='reseller_designation_id'
                aria-label='Reseller Designation Type'
                data-control='select2'
                data-placeholder='Reseller Designation Type...'
                data-dropdown-parent='#kt_modal_add_customer'
                className='form-select form-select-solid fw-bold'
              >
                <option>Select Reseller Designation Type ...</option>
                {getDesignationTypes?.map((designation_type: any, index) => {
                  return (
                    <option key={index} value={designation_type?.id}>
                      {designation_type.name}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage
                name='reseller_designation_id'
                className='text-danger'
                component='span'
              />
              {/*end::Input*/}
            </div>
            {/*begin::Input group*/}
            <div className='d-flex flex-column mb-7 fv-row'>
              {/*begin::Label*/}
              <label className='required fs-6 fw-semibold mb-2'>Reseller Name</label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <Field
                type='text'
                className='form-control form-control-solid'
                placeholder='Reseller Name'
                name='reseller_name'
                id='reseller_name'
              />
              <ErrorMessage name='reseller_name' className='text-danger' component='span' />
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className='d-flex flex-column mb-7 fv-row'>
              {/*begin::Label*/}
              <label className='fs-6 fw-semibold mb-2'>
                <span className='required'>Reseller Credit Zone Type </span>
              </label>
              {/*end::Label*/}
              {/*begin::Input*/}
              <Field
                as='select'
                name='credit_zone_type_id'
                id='credit_zone_type_id'
                aria-label='Reseller Credit Zone Type'
                data-control='select2'
                data-placeholder='Reseller Credit Zone Type...'
                data-dropdown-parent='#kt_modal_add_customer'
                className='form-select form-select-solid fw-bold'
              >
                <option value=''>Select Credit Zone Type ...</option>
                {getCreditZone?.map((credit_zone: any, index) => {
                  return (
                    <option key={index} value={credit_zone?.id}>
                      {credit_zone?.name}
                    </option>
                  )
                })}
              </Field>
              <ErrorMessage name='credit_zone_type_id' className='text-danger' component='span' />
              {/*end::Input*/}
            </div>
            {/*end::Input group*/}
            {/*end::Input group*/}
            <div className='row g-9 mb-7'>
              {/*begin::Col*/}
              <div className='col-md-6 fv-row'>
                {/*begin::Label*/}
                <label className='required fs-6 fw-semibold mb-2'> Reseller Login mail Id </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <Field
                  type='email'
                  className='form-control form-control-solid'
                  placeholder='login@mailid.com'
                  name='reseller_email_id'
                  id='reseller_email_id'
                />
                <ErrorMessage name='reseller_email_id' className='text-danger' component='span' />
                {/*end::Input*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className='col-md-6 fv-row'>
                {/*begin::Label*/}
                <label className='required fs-6 fw-semibold mb-2'> Login Password</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <Field
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='*********'
                  name='reseller_password'
                  id='reseller_password'
                />
                <ErrorMessage name='reseller_password' className='text-danger' component='span' />
                {/*end::Input*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Input group*/}
            <div
              className='fw-bold fs-3 rotate collapsible mb-7'
              data-bs-toggle='collapse'
              // href="#kt_modal_add_customer_billing_info"
              role='button'
              aria-expanded='false'
              aria-controls='kt_customer_view_details'
            >
              Reseller Contact Information
              <span className='ms-2 rotate-180'>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                <span className='svg-icon svg-icon-3'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
              </span>
            </div>
            {/*end::Billing toggle*/}
            {/*begin::Billing form*/}
            <div id='kt_modal_add_customer_billing_info' className='collapse show'>
              {/*begin::Input group*/}
              <div className='row g-9 mb-7'>
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className='required fs-6 fw-semibold mb-2'> Reseller Contact No </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reseller Contact No'
                    name='reseller_contact_no'
                    id='reseller_contact_no'
                  />
                  <ErrorMessage
                    name='reseller_contact_no'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  <label className='required fs-6 fw-semibold mb-2'>Date of Birth</label>
                  <Field
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Reseller Mail Id'
                    name='date_of_birth'
                    id='date_of_birth'
                  />
                  <ErrorMessage name='date_of_birth' className='text-danger' component='span' />
                </div>
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className='row g-9 mb-7'>
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className='required fs-6 fw-semibold mb-2'> Reseller Pancad No </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reseller Pancad No'
                    name='reseller_pancard'
                    id='reseller_pancard'
                    onInput={(event: any) => convertToUpperCase(event)}
                  />
                  <ErrorMessage name='reseller_pancard' className='text-danger' component='span' />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className='required fs-6 fw-semibold mb-2'>Reseller Adhar card No </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reseller Adhar card No'
                    name='reseller_adhar_card'
                    id='reseller_adhar_card'
                  />
                  <ErrorMessage
                    name='reseller_adhar_card'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className='d-flex flex-column mb-7 fv-row'>
                {/*begin::Label*/}
                <label className='required fs-6 fw-semibold mb-2'>Reseller Address Line </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <Field
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Reseller Address Line '
                  name='reseller_address'
                  id='reseller_address'
                />
                <ErrorMessage name='reseller_address' className='text-danger' component='span' />
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className='row g-9 mb-7'>
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className='fs-6 fw-semibold mb-2'> Reference 01</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reference 01 '
                    name='reseller_reference_1'
                    id='reseller_reference_1'
                  />
                  <ErrorMessage
                    name='reseller_reference_1'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className=' fs-6 fw-semibold mb-2'> Reference Contact No </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reference Contact No'
                    name='reseller_reference_contact_1'
                    id='reseller_reference_contact_1'
                  />
                  <ErrorMessage
                    name='reseller_reference_contact_1'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className='row g-9 mb-7'>
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className=' fs-6 fw-semibold mb-2'> Reference 02</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reference 02'
                    name='reseller_reference_2'
                    id='reseller_reference_2'
                  />
                  <ErrorMessage
                    name='reseller_reference_2'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className='col-md-6 fv-row'>
                  {/*begin::Label*/}
                  <label className=' fs-6 fw-semibold mb-2'> Reference Contact No </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Reference Contact No'
                    name='reseller_reference_contact_2'
                    id='reseller_reference_contact_2'
                  />
                  <ErrorMessage
                    name='reseller_reference_contact_2'
                    className='text-danger'
                    component='span'
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*begin::Input group*/}
              <div className='d-flex flex-column mb-7 fv-row'>
                {/*begin::Label*/}
                <label className='fs-6 fw-semibold mb-2'>
                  Documenrs Up Loded | Pancard | Adhar Card | Driving Lience
                </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <div className='me-0'>
                  {/*begin::Label*/}
                  <div className='form-group'>
                    <Dropzone
                      // multiple={true}
                      onChangeStatus={handleChangeImages}
                      LayoutComponent={LayoutImg}
                      inputContent='Upload images'
                      accept='image/*'
                      maxFiles={20}
                    />
                  </div>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <div className='fs-7 fw-semibold text-muted'>
                    {' '}
                    please Uploded Documnets Photos
                  </div>
                  {/*end::Input*/}
                </div>
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*end::Input group*/}
              {/*end::Input group*/}
              {/* <div className='d-flex flex-stack'>
                <div className='me-5'>
                  <label className='fs-6 fw-semibold'>Reseller Access Status</label>
                  <div className='fs-7 fw-semibold text-muted'>
                    {' '}
                    please check Reseller Condications Status
                  </div>
                </div>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <Field className='form-check-input' name='status' id='status' type='checkbox' />
                  <span
                    className='form-check-label fw-semibold text-muted'
                  >
                    Active
                  </span>
                </label>
              </div> */}
            </div>
            {/*end::Billing form*/}
          </div>
          {/*end::Scroll*/}
        </div>
        {error && <p className='text-center text-danger'>Something Went Wrong</p>}
        {success && <p className='text-center text-success'>Store Added Successfully</p>}
        {/*end::Modal body*/}
        {/*begin::Modal footer*/}
        <div className='modal-footer flex-center'>
          {/*begin::Button*/}
          <button
            type='reset'
            id='kt_modal_add_customer_cancel'
            data-bs-dismiss='modal'
            data-kt-users-modal-action='close'
            className='btn btn-light me-3'
          >
            Cancel
          </button>
          {/*end::Button*/}
          {/*begin::Button*/}
          <button type='submit' id='kt_modal_add_customer_submit' className='btn btn-primary'>
            {!loading && <span className='indicator-label'>Add Reseller</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            )}
          </button>
          {/*end::Button*/}
        </div>
        {/*end::Modal footer*/}
      </Form>
    </Formik>
  )
}
