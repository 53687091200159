import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  //Categories
  const CategoriesWrapper = lazy(() => import('../pages/categories/CategoriesPage'))
  //Sub Category
  const SubCategoryWrapper = lazy(() => import('../pages/subcategories/subcategory'))
  //Products
  const ProductsWrapper = lazy(() => import('../pages/products/productspage'))
  //Users
  const UsersWrapper = lazy(() => import('../pages/users/userspage'))
  //Emplyee
  const AdminsWrapper = lazy(() => import('../pages/admins/adminspage'))
  //Orders
  const OrderManagmentWrapper = lazy(() => import('../pages/ordermanagment/ordermanagmentpage'))
  //Coupons
  const CouponsWrapper = lazy(() => import('../pages/coupun/couponpages'))
  //Product Container
  const ProductContainerWrapper = lazy(() => import('../pages/productoptions/productcontainer'))

  //Product Container Group
  const ProductOptionValuesWrapper = lazy(
    () => import('../pages/productoptionvalues/productoptionvalues')
  )

  //Store Managemnet
  const StoreManagementWrapper = lazy(() => import('../pages/storemanagement/storemanagement'))

  //Store Partner Managemnet
  const StorePartnerManagement = lazy(
    () => import('../pages/Store_Partner_Management/store_partner_management')
  )

  // Reseller Management
  const ResellerManagement = lazy(
    () => import('../pages/Reseller_Management/Reseller_Management_main')
  )

  // Credit Zone
  const CreditZoneManagement = lazy(
    () => import('../pages/Reseller_Management/components/credit_zone_pages/Credit_zone_Main_Page')
  )

  // Credit Zone
  const ResellerDesignationTypes = lazy(
    () =>
      import(
        '../pages/Reseller_Management/components/reseller_designation_type_pages/Reseller_Designation_Type_Main_Page'
      )
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/categories' component={CategoriesWrapper} />
        <Route path='/subcategories' component={SubCategoryWrapper} />
        <Route path='/products' component={ProductsWrapper} />
        <Route path='/users' component={UsersWrapper} />
        <Route path='/admins' component={AdminsWrapper} />
        <Route path='/orders' component={OrderManagmentWrapper} />
        <Route path='/coupons' component={CouponsWrapper} />
        <Route path='/productcontainer' component={ProductContainerWrapper} />
        <Route path='/productoptionvalues' component={ProductOptionValuesWrapper} />

        {/* Store */}
        <Route path='/storemanagement' component={StoreManagementWrapper} />

        {/* Store  */}
        <Route path='/store_partner_management' component={StorePartnerManagement} />

        {/* Reseller Management  */}
        <Route path='/reseller_management' component={ResellerManagement} />

        {/* Credit Zone Management  */}
        <Route path='/credit_zone' component={CreditZoneManagement} />

        {/* Reseller Designation Type Management  */}
        <Route path='/reseller_designation' component={ResellerDesignationTypes} />

        {/*Other Routes not required */}
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
