/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues, Field, ErrorMessage, validateYupSchema} from 'formik'
import * as Yup from 'yup'
// import http from '../../../../http-common'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import http from '../../../../http-common'
import StoreAdminAddResellerFormModel from './StoreAdminAddResellerFormModel'
import SuperAdminAddResellerFormModel from './SuperAdminAddResellerFormModel'

const AddResellerModel: FC = () => {
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  return (
    <>
      {/*begin::Modal - Add Store - Add*/}
      <div className='modal fade' id='kt_modal_add_reseller_model' tabIndex={-1} aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/*begin::Modal content*/}
          <div className='modal-content'>
            {/*begin::Form*/}
            {getUser?.user_type && getUser?.user_type === 'Admin' ? (
              <SuperAdminAddResellerFormModel />
            ) : (
              <StoreAdminAddResellerFormModel />
            )}

            {/*end::Form*/}
          </div>
        </div>
      </div>
      {/*begin::Modal - End Store - Add*/}
    </>
  )
}

export {AddResellerModel}
