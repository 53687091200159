/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function StoreAsideMenuItem() {
  const intl = useIntl()
  const getUser:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual);

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/*<AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>

      {getUser.employe_type && getUser.employe_type == 'Admin' && 
        <AsideMenuItemWithSub
            to='/storeemployee'
            title='Store Employees'
            fontIcon='users-cog'
            icon='/media/icons/duotune/general/gen022.svg'
        >
            <AsideMenuItem to='/storeemployee/employees' title='View Store Employees' hasBullet={true} />
        </AsideMenuItemWithSub>
      }

      {/* users */}
      <AsideMenuItemWithSub
        to='/users'
        title='Resellers'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
          <AsideMenuItem to='/users/reseller_user' title='Reseller Users' hasBullet={true} />
          {/* <AsideMenuItem to='/users/business_user' title='Business' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

    </>
  )
}
