/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/*<AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />*/}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>

      {/* users */}
      <AsideMenuItemWithSub
        to='/users'
        title='Users Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/users/users' title='Users' hasBullet={true} />
        <AsideMenuItem to='/users/add_user' title='Add Users' hasBullet={true} />
        <AsideMenuItem to='/users/reseller_user' title='Resellers' hasBullet={true} />
        <AsideMenuItem
          to='/users/reseller_type'
          title='Add Resellers Credit Type'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/users/reseller_designation'
          title='Add Resellers Designation Type'
          hasBullet={true}
        />
        {/* <AsideMenuItem to='/users/business_user' title='Business' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* employees */}
      <AsideMenuItemWithSub
        to='/admins'
        title='Admin Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/admins/view' title='View Admins' hasBullet={true} />
        {/*<AsideMenuItem to='/employees/add_employee' title='Add Employee' hasBullet={true} />*/}
        <AsideMenuItem to='/admins/employee_type' title='Employee Type' hasBullet={true} />
        <AsideMenuItem to='/admins/employee_skills' title='Employee Skills' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/storemanagement'
        title='Store Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/storemanagement/stores' title='Add Smart Store' hasBullet={true} />
        {/* <AsideMenuItem to='/storemanagement/admins' title='View Store Admins' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/store_partner_management'
        title='Partner Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/store_partner_management/store_partners'
          title='Add Store Admin'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/store_partner_management/store_partner_overview'
          title='View Store Partner Overview'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/reseller_management'
        title='Reseller Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/reseller_management/reseller' title='Reseller List' hasBullet={true} />
        <AsideMenuItem
          to='/reseller_management/pending_list'
          title='Reseller Pending List'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reseller_management/rejected_list'
          title='Reseller Rejected List'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/credit_zone/credit_zone_settings'
          title='Credit Zone Settings'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reseller_designation/reseller_designation_types'
          title='Reseller Designation Types'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* product options */}
      <AsideMenuItemWithSub
        to='/productcontainer'
        title='Prodduct Containers'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/productcontainer/view'
          title='View Product Container'
          hasBullet={true}
        />
        <AsideMenuItem to='/productcontainer/add' title='Add Product Container' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* Product Options */}
      <AsideMenuItemWithSub
        to='/productoptionvalues'
        title='Prodduct Containers Groups'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/productoptionvalues/view'
          title='View Product Container Groups'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* Categories */}
      <AsideMenuItemWithSub
        to='/categories'
        title='Product Master Categories'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/categories/view' title='Product Master Category' hasBullet={true} />
        <AsideMenuItem
          to='/categories/add_category'
          title='Add Product Master Category'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* Sub Category */}
      <AsideMenuItemWithSub
        to='/subcategories'
        title='Product Master Sub Categories'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/subcategories/view'
          title='Product Master Sub Category'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/subcategories/add_category'
          title='Add Product Master Sub Category'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* products */}
      <AsideMenuItemWithSub
        to='/products'
        title='Products'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/products/view' title='View Product' hasBullet={true} />
        <AsideMenuItem to='/products/add_product' title='Add Product' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* orders */}
      <AsideMenuItemWithSub
        to='/orders'
        title='Order Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/orders/view' title='View Order' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* coupons */}
      <AsideMenuItemWithSub
        to='/coupons'
        title='Coupon Management'
        fontIcon='users-cog'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/coupons/view_coupon' title='View Coupons' hasBullet={true} />
        {/* <AsideMenuItem to='/coupons/add_coupon' title='Add Coupon' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
