import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
// import ResellerManagement from '../pages/Reseller_Management/Reseller_Management_main'

export function StoreAdminPrivateRoutes() {
  const StoreEmployeeWrapper = lazy(() => import('../pages/storemanagement/storeemployees'))
  const UsersWrapper = lazy(() => import('../pages/users/storeusers'))
  const ResellerManagement = lazy(
    () => import('../pages/Reseller_Management/Reseller_Management_main')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/storeemployee' component={StoreEmployeeWrapper} />
        <Route path='/users' component={UsersWrapper} />

        {/* Reseller Management Route */}
        <Route path='/reseller_management' component={ResellerManagement} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
