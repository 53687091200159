import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import { getCookie } from "typescript-cookie"
import http from "../../../../http-common";


const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
      email: email,
      password: password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.get<{result: boolean}>(REQUEST_PASSWORD_URL, {
    params: {
      email: email,
    },
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  // const user = {
  //   data: {
  //     api_token: "sdfsdfsd34sg456trtgfxdg",
  //     created_at: "2022-03-30T12:17:50.000000Z",
  //     email: "admin@demo.com",
  //     email_verified_at: "2022-03-30T12:17:50.000000Z",
  //     first_name: "Maeve",
  //     id: 2,
  //     last_name: "Casper",
  //     updated_at: "2022-03-30T12:17:50.000000Z",
  //     username:"admin@demo.com",
  //     password:'demo'
  //   }
  // }
  // // console.log(user);
  // return(
  //   user
  // )
    let access_token = getCookie('access_token');
    return http.post('getuserbytoken',
     {
         api_token: access_token,
     }
    )
}
