import axios from 'axios'
import {getCookie} from 'typescript-cookie'

let access_token = getCookie('access_token')
// console.log(access_token);
if (!access_token) {
  access_token = ''
}
export default axios.create({
  baseURL: 'https://adminapi.thevfx.studio/',
  // baseURL: 'http://localhost:3001/',
  headers: {
    'Content-type': 'application/json',
    'x-access-token': access_token,
  },
})

// Admin Server
export const baseUrl = 'https://adminapi.thevfx.studio/'
// export const baseUrl = 'http://localhost:3001/'

// User Server
export const baseUrl2 = 'https://userapi.thevfx.studio/'
// export const baseUrl2 = 'http://localhost:3002/'
