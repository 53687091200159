/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues, Field, ErrorMessage, validateYupSchema} from 'formik'
import * as Yup from 'yup'
import http from '../../../../http-common'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const AddResellerDesignationTypesModel: FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const initialValues = {
    name: '',
    status: '',
    created_by: getUser?.id,
  }

  const validateSchema = Yup.object().shape({
    name: Yup.string().required('Store Admin Name is required'),
  })

  const onsubmit = (data: any) => {
    setLoading(true)
    setTimeout(() => {
      http
        .post('/reselller_designation_types/add_reseller_type', data)
        .then((response) => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          setError(true)
          console.log(error)
        })
    }, 1000)
  }

  return (
    <>
      {/*begin::Modal - Add Store - Add*/}
      <div
        className='modal fade'
        id='kt_modal_add_reseller_designation_model'
        tabIndex={-1}
        aria-hidden='true'
      >
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/*begin::Modal content*/}
          <div className='modal-content'>
            {/*begin::Form*/}
            <Formik
              initialValues={initialValues}
              validationSchema={validateSchema}
              onSubmit={onsubmit}
            >
              <Form
                className='form'
                action='#'
                id='kt_modal_add_customer_form'
                data-kt-redirect='../../demo1/dist/apps/customers/list.html'
              >
                {/*begin::Modal header*/}
                <div className='modal-header' id='kt_modal_add_customer_header'>
                  {/*begin::Modal title*/}
                  <h2 className='fw-bold'>Add Reseller Designation Type</h2>
                  {/*end::Modal title*/}
                  {/*begin::Close*/}
                  <div
                    id='kt_modal_add_customer_close'
                    data-bs-dismiss='modal'
                    data-kt-users-modal-action='close'
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                  >
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className='svg-icon svg-icon-1'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x={6}
                          y='17.3137'
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(-45 6 17.3137)'
                          fill='currentColor'
                        />
                        <rect
                          x='7.41422'
                          y={6}
                          width={16}
                          height={2}
                          rx={1}
                          transform='rotate(45 7.41422 6)'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {/*end::Svg Icon*/}
                  </div>
                  {/*end::Close*/}
                </div>
                {/*end::Modal header*/}
                {/*begin::Modal body*/}
                <div className='modal-body py-10 px-lg-17'>
                  {/*begin::Scroll*/}
                  <div
                    className='scroll-y me-n7 pe-7'
                    id='kt_modal_add_customer_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_customer_header'
                    data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
                    data-kt-scroll-offset='300px'
                  >
                    {/*end::Billing toggle*/}
                    {/*begin::Input group*/}
                    <div className='d-flex flex-column mb-7 fv-row'>
                      {/*begin::Label*/}
                      <label className='required fs-6 fw-semibold mb-2'>
                        Enter Reseller Designation{' '}
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <Field
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Enter Reseller Designation'
                        name='name'
                        id='name'
                      />
                      <ErrorMessage name='name' className='text-danger' component='span' />
                      {/*end::Input*/}
                    </div>
                    <div className='d-flex flex-stack'>
                      {/*begin::Label*/}
                      <div className='me-5'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-semibold'>Reseller Designation Type Status</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <div className='fs-7 fw-semibold text-muted'>
                          {' '}
                          Please Check Reseller Designation Type Status
                        </div>
                        {/*end::Input*/}
                      </div>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      <label className='form-check form-switch form-check-custom form-check-solid'>
                        {/*begin::Input*/}
                        <Field
                          className='form-check-input'
                          name='status'
                          id='status'
                          type='checkbox'
                        />
                        {/*end::Input*/}
                        {/*begin::Label*/}
                        <span className='form-check-label fw-semibold text-muted'>Active</span>
                        {/*end::Label*/}
                      </label>
                      {/*end::Switch*/}
                    </div>
                    {/*end::Billing form*/}
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                </div>
                {error && <p className='text-center text-danger'>Something Went Wrong</p>}
                {success && (
                  <p className='text-center text-success'>Credit Zone Added Successfully</p>
                )}
                {/*end::Modal body*/}
                {/*begin::Modal footer*/}
                <div className='modal-footer flex-center'>
                  {/*begin::Button*/}
                  <button
                    type='reset'
                    id='kt_modal_add_customer_cancel'
                    data-bs-dismiss='modal'
                    data-kt-users-modal-action='close'
                    className='btn btn-light me-3'
                  >
                    Cancel
                  </button>
                  {/*end::Button*/}
                  {/*begin::Button*/}
                  <button
                    type='submit'
                    id='kt_modal_add_customer_submit'
                    className='btn btn-primary'
                  >
                    {!loading && <span className='indicator-label'>Add Designation</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                  {/*end::Button*/}
                </div>
                {/*end::Modal footer*/}
              </Form>
            </Formik>

            {/*end::Form*/}
          </div>
        </div>
      </div>
      {/*begin::Modal - End Store - Add*/}
    </>
  )
}

export {AddResellerDesignationTypesModel}
