/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import http from '../../../../../http-common'
import {useHistory} from 'react-router-dom'

interface ResellerApproveModelProps {
  resellerId: string
}

const ResellerApproveModel: React.FC<ResellerApproveModelProps> = (resellerId) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const history = useHistory()

  const onSubmit = () => {
    const reseller_id = resellerId?.resellerId
    setLoading(true)
    setTimeout(() => {
      const data = {
        status: 'Active',
        updated_by: getUser?.id,
      }
      http
        .post(`/resellers/reseller_status_update/${reseller_id}`, data)
        .then((response) => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            history.push('/reseller_management/pending_list')
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          setError(true)
          console.log(error)
        })
    }, 1000)
  }
  return (
    <div className='modal fade' id='kt_modal_reseller_approve_model' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Confirmation Message</h1>

              <div className='text-muted fw-bold fs-5'>
                Are you sure want to
                <a href='#' className='link-primary fw-bolder me-1'>
                  {' '}
                  Approve
                </a>
                the reseller?
              </div>
            </div>

            <div className='text-center fw-bolder w-100 mb-8'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/custom_icons/exclamation-mark.png')}
                className='h-60px me-3'
              />
            </div>
            {error && <p className='text-center text-danger'>Something Went Wrong</p>}
            {success && <p className='text-center text-success'>Reseller Successfully Approved</p>}
            {/*begin::Modal footer*/}
            <div className='modal-footer flex-center'>
              {/*begin::Button*/}
              <button
                type='reset'
                id='kt_modal_add_customer_cancel'
                data-bs-dismiss='modal'
                data-kt-users-modal-action='close'
                className='btn btn-light me-3'
              >
                Cancel
              </button>
              {/*end::Button*/}
              {/*begin::Button*/}
              <button
                onClick={onSubmit}
                id='kt_modal_add_customer_submit'
                className='btn btn-primary'
              >
                {!loading && <span className='indicator-label'>Approve</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </button>
              {/*end::Button*/}
            </div>
            {/*end::Modal footer*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ResellerApproveModel}
