/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues, Field, ErrorMessage, validateYupSchema} from 'formik'
import * as Yup from 'yup'
import http from '../../../../http-common'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const AddStoreModel: FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [getPincodeData, setPincodeData] = useState(null as any)
  const getUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const initialValues = {
    store_name: '',
    contact_number: '',
    store_email: '',
    store_address: '',
    pincode: '',
    city_town_area: '',
    district: '',
    state: '',
    country: '',
    status: '',
    created_by: getUser.id,
  }

  useEffect(() => {
    initialValues.state = getPincodeData?.state_name || ''
    initialValues.district = getPincodeData?.district || ''
    initialValues.country = getPincodeData?.country || ''
  }, [getPincodeData])

  const validationSchema = Yup.object().shape({
    store_name: Yup.string().required('Store Name is required'),
    contact_number: Yup.string()
      .required('Store Contact No is required')
      .matches(/^[0-9]{10}$/, 'Invalid phone number. Please enter a 10-digit number')
      .test(
        'Unique Store Contact No',
        'Store Contact No already exits',
        function (contact_number: any) {
          return new Promise((resolve, reject) => {
            http.get(`stores/check_store_contact/${contact_number}/0`).then((response) => {
              if (response.data.notExist === true) {
                resolve(true)
              } else {
                resolve(false)
              }
            })
          })
        }
      ),
    store_email: Yup.string()
      .required('Store Email No is required')
      .email('Invalid email address. Please enter a valid email')
      .test('Unique Store Email', 'Store Email already exits', function (store_email: any) {
        return new Promise((resolve, reject) => {
          http.get(`stores/check_store_email/${store_email}/0`).then((response) => {
            if (response.data.notExist === true) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        })
      }),
    store_address: Yup.string().required('Store Address is required'),
    pincode: Yup.string().required('Pincode is required'),
    city_town_area: Yup.string()
      .required('City / Town / Area  is required')
      .test(
        'Unique City/Town/Area',
        'City/Town/Area already exists',
        function (city_town_area: any) {
          return new Promise((resolve, reject) => {
            http.get(`/stores/check_store_location/${city_town_area}/0`).then((response) => {
              if (response.data.notExist === true) {
                resolve(true)
              } else {
                resolve(false)
              }
            })
          })
        }
      ),
    district: Yup.string().required('District Name is required'),
    state: Yup.string().required('State Name is required'),
    country: Yup.string().required('Country is required'),
  })

  const onSubmit = (data: any) => {
    console.log(data)
    setLoading(true)
    setTimeout(() => {
      http
        .post('/stores/add_store', data)
        .then((response) => {
          setLoading(false)
          setSuccess(true)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch((error) => {
          setLoading(false)
          setError(true)
          console.log(error)
        })
    }, 1000)
  }

  const handleChange = async (e: any, setFieldValue: any) => {
    try {
      const key_value = e.target.value
      const getData = await http.get(`/pincode_directories/get_pincode/${key_value}`)
      setPincodeData(getData?.data || null)
      setFieldValue('district', getData?.data?.district || '')
      setFieldValue('state', getData?.data?.state_name || '')
      setFieldValue('country', getData?.data?.country || '')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {/*begin::Modal - Add Store - Add*/}
      <div className='modal fade' id='kt_modal_add_smart_store' tabIndex={-1} aria-hidden='true'>
        {/*begin::Modal dialog*/}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/*begin::Modal content*/}
          <div className='modal-content'>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({values, setFieldValue}) => (
                <Form className='form' id='kt_modal_add_customer_form'>
                  {/*begin::Modal header*/}
                  <div className='modal-header' id='kt_modal_add_customer_header'>
                    {/*begin::Modal title*/}
                    <h2 className='fw-bold'>Add Smart Store in Location</h2>
                    {/*end::Modal title*/}
                    {/*begin::Close*/}
                    <div
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='close'
                      id='kt_modal_add_customer_close'
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                    >
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                      <span className='svg-icon svg-icon-1'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x={6}
                            y='17.3137'
                            width={16}
                            height={2}
                            rx={1}
                            transform='rotate(-45 6 17.3137)'
                            fill='currentColor'
                          />
                          <rect
                            x='7.41422'
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform='rotate(45 7.41422 6)'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </div>
                    {/*end::Close*/}
                  </div>
                  {/*end::Modal header*/}
                  {/*begin::Modal body*/}
                  <div className='modal-body py-10 px-lg-17'>
                    {/*begin::Scroll*/}
                    <div
                      className='scroll-y me-n7 pe-7'
                      id='kt_modal_add_customer_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_customer_header'
                      data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      {/*end::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-12 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>Store Name </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Enter The Store Name'
                            name='store_name'
                            id='store_name'
                          />
                          <ErrorMessage
                            name='store_name'
                            component='span'
                            className='text-danger'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>
                            Store Contact No{' '}
                          </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Store Contact No'
                            name='contact_number'
                            id='contact_number'
                          />
                          <ErrorMessage
                            name='contact_number'
                            component='span'
                            className='text-danger'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='required fs-6 fw-semibold mb-2'>Store Mail Id</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Store@Mail.com'
                            name='store_email'
                            id='store_email'
                          />
                          <ErrorMessage
                            name='store_email'
                            component='span'
                            className='text-danger'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*begin::Billing form*/}
                      {/*begin::Input group*/}
                      {/*begin::Input group*/}
                      <div className='d-flex flex-column mb-7 fv-row'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'>Store Address</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Enter Store Address'
                          name='store_address'
                          id='store_address'
                        />
                        <ErrorMessage
                          name='store_address'
                          component='span'
                          className='text-danger'
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Input group*/}
                      <div className='fv-row mb-7 fv-plugins-icon-container'>
                        {/*begin::Label*/}
                        <label className='required fs-6 fw-semibold mb-2'> Pincode </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Enter The Area Pincode'
                          name='pincode'
                          id='pincode'
                          onKeyUp={(e: any) => handleChange(e, setFieldValue)}
                        />
                        <ErrorMessage name='pincode' component='span' className='text-danger' />
                        {/*end::Input*/}
                        <div className='fv-plugins-message-container invalid-feedback' />
                      </div>
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold mb-2'> City/ Town / area </label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='City/Town/Area'
                            name='city_town_area'
                            id='city_town_area'
                          />
                          <ErrorMessage
                            name='city_town_area'
                            component='span'
                            className='text-danger'
                          />
                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold mb-2'>District</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='District'
                            name='district'
                            id='district'
                          />
                          <ErrorMessage name='district' component='span' className='text-danger' />

                          {/*end::*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      <div className='row g-9 mb-7'>
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold mb-2'> State</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}

                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='State'
                            name='state'
                            id='state'
                          />
                          <ErrorMessage name='state' component='span' className='text-danger' />

                          {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className='col-md-6 fv-row'>
                          {/*begin::Label*/}
                          <label className='fs-6 fw-semibold mb-2'>Country</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}

                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            placeholder='Country'
                            name='country'
                            id='country'
                          />
                          <ErrorMessage name='country' component='span' className='text-danger' />

                          {/*end::*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Input group*/}
                      {/*end::Billing form*/}
                    </div>
                    {/*end::Scroll*/}
                    <div className='d-flex flex-stack'>
                      {/*begin::Label*/}
                      <div className='me-5'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-semibold'>Smart Store Access Status</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <div className='fs-7 fw-semibold text-muted'>
                          {' '}
                          please check Store Status
                        </div>
                        {/*end::Input*/}
                      </div>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      {/*begin::Input*/}
                      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                        <Field
                          type='checkbox'
                          className='form-check-input'
                          name='status'
                          id='status'
                        />
                        <span
                          className='form-check-label fw-semibold text-muted'
                          // htmlFor="kt_modal_add_customer_billing"
                        >
                          Active
                        </span>
                      </label>
                      <ErrorMessage name='status' component='span' className='text-danger' />

                      {/*end::Input*/}
                    </div>
                    {error && <p className='text-center text-danger'>Something Went Wrong</p>}
                    {success && (
                      <p className='text-center text-success'>Store Added Successfully</p>
                    )}
                  </div>
                  {/*end::Modal body*/}
                  {/*begin::Modal footer*/}
                  <div className='modal-footer flex-center'>
                    {/*begin::Button*/}
                    <button
                      type='reset'
                      id='kt_modal_add_customer_cancel'
                      data-bs-dismiss='modal'
                      data-kt-users-modal-action='close'
                      className='btn btn-light me-3'
                    >
                      Discard
                    </button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                    <button
                      type='submit'
                      id='kt_modal_add_customer_submit'
                      className='btn btn-primary'
                    >
                      {!loading && <span className='indicator-label'>Save</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      )}
                    </button>
                    {/*end::Button*/}
                  </div>
                  {/*end::Modal footer*/}
                </Form>
              )}
              {/*end::Form*/}
            </Formik>
          </div>
        </div>
      </div>
      {/*begin::Modal - End Store - Add*/}
    </>
  )
}

export {AddStoreModel}
